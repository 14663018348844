import React from 'react'
import Helmet from 'react-helmet'
import Base from '../Base/base'
import BaseGrid from '../BaseGrid'

class Page extends React.Component {
  render() {
    const { children, pageTitle } = this.props

    const {
      title,
      subtitle,
    } = this.props.data.site.siteMetadata

    const pageSubtitle = pageTitle ? ` - ${pageTitle}` : ''
    const fullTitle = title + pageSubtitle

    return (
      <>
        <Helmet>
          <html lang="en"/>
          {/*<link rel="canonical" href="http://mysite.com/example" />*/}
          <title>{fullTitle}</title>
          <meta name="description" content={subtitle}/>
        </Helmet>
        <Base/>
        <BaseGrid {...this.props}>
          {children}
        </BaseGrid>
      </>
    )
  }
}

export default Page
