import React from 'react'
import { graphql } from 'gatsby'
import MainContent from '../components/MainContent'
import Page from '../components/Page'

class NotFoundRoute extends React.Component {
  render() {
    return (
      <Page pageTitle="404" {...this.props}>
        <MainContent>
          <div className="mainContentBG">
            <p>
              You just hit a route that doesn&#39;t exist... the sadness.
            </p>
          </div>
        </MainContent>
      </Page>
    )
  }
}

export default NotFoundRoute

export const pageQuery = graphql`
  query query404 {
    ...siteQuery
  }
`
