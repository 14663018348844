import React from 'react'
import './style.scss'

class MainContent extends React.Component {
  render() {
    const { children, forTiles } = this.props

    const className = forTiles ? 'mainContentForTiles' : 'mainContent'

    return (
      <div className={className}>
        {children}
      </div>
    )
  }
}

export default MainContent
