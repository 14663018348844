import React from 'react'
import '../../assets/fontello/css/fontello.css'
import './style.scss'

class Links extends React.Component {
  render() {
    const author = this.props.data

    const mkLink = (id, cn, builder) => {
      return (
        !id ? <></> :
          <li className="links__list-item">
            <a
              href={builder(id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={cn}/>
            </a>
          </li>
      )
    }

    const email = mkLink(author.email, 'icon-mail', id => `mailto:${id}`)
    const tw = mkLink(author.twitter, 'icon-twitter', id => `https://www.twitter.com/${id}`)
    const fb = mkLink(author.facebook, 'icon-facebook', id => `https://www.facebook.com/${id}`)
    const li = mkLink(author.linkedin, 'icon-linkedin', id => `https://www.linkedin.com/in/${id}`)
    const gh = mkLink(author.github, 'icon-github', id => `https://www.github.com/${id}`)
    const so = mkLink(author.stackoverflow, 'icon-stackoverflow', id => `https://www.stackoverflow.com/users/${id}`)
    const ig = mkLink(author.instagram, 'icon-instagram', id => `https://www.instagram.com/${id}`)
    const skype = mkLink(author.skype, 'icon-skype', id => `https://join.skype.com/invite/${id}`)
    const slack = mkLink(author.slack, 'icon-slack', id => `https://cdmtteam.slack.com/team/${id}`)
    const paypal = mkLink(author.paypal, 'icon-paypal', id => `https://paypal.me/${id}`)
    const venmo = mkLink(author.venmo, 'icon-vimeo', id => `https://www.venmo.com/${id}`)

    return (
      <div className="links">
        <ul className="links__list">
          {gh}
          {li}
          {fb}
          {tw}
          {so}
          {ig}
          {skype}
          {slack}
          {email}
          {paypal}
          {venmo}
        </ul>
      </div>
    )
  }
}

export default Links
