import React from 'react'
import './style.scss'

class GradientBackground extends React.Component {
  render() {
    return (
      // Attach linear gradient to this class
      <div className="gradientBackground"/>
    )
  }
}

export default GradientBackground
