import React from 'react'
import { Link } from 'gatsby'
import Menu from '../Menu'
import profilePic from '../../assets/images/randyunger.png'
import './style.scss'

class Sidebar extends React.Component {
  render() {
    const {
      author,
      subtitle,
      menu,
    } = this.props.data.site.siteMetadata

    const authorPicBlock = (
      <>
        <Link to="/">
          <img
            src={profilePic}
            className="sidebar__author-photo"
            width="75"
            height="75"
            alt={author.name}
          />
        </Link>
        <h2 className="sidebar__author-pic-title">
          <Link className="sidebar__author-pic-title-link" to="/">
            {author.name}
          </Link>
        </h2>
      </>
    )

    return (
      <div className="sidebar">
        <div className="sidebar__author">
          <div className="sidebar__author-pic">{authorPicBlock}</div>
          <div className="sidebar-subtitle">
            <div className="sidebar-subtitle-text"> {subtitle} </div>
          </div>
        </div>
        <Menu data={menu} />
      </div>
    )
  }
}

export default Sidebar
