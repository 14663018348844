import React from 'react'
import '../../assets/scss/init.scss'
import DoodleBackground from '../DoodleBackground'
import GradientBackground from '../GradientBackground'

class Base extends React.Component {
  render() {
    return (
      <>
        <DoodleBackground/>
        <GradientBackground/>
      </>
    )
  }
}

export default Base
