import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const FlexLink = ({ children, to, activeClassName, ...others }) => {
  const isInternalLink = /^\/(?!\/)/.test(to)

  if (isInternalLink) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...others}>
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...others}>
        {children}
      </a>
    )
  }
}

export default FlexLink
