import React from 'react'

if (typeof document !== 'undefined') {
  // Don't let gatsby execute css-doodle while compiling HTML
  // eslint-disable-next-line global-require
  require('css-doodle')
}

class Doodle extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loaded: false }
  }

  componentDidMount() {
    this.timeoutHandle = setTimeout(() => {
      this.setState({
        loaded: true,
      })
      this.timeoutHandle = 0
    }, 1)
  }

  componentWillUnmount() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle)
      this.timeoutHandle = 0
    }
  }

  render() {
    // For some reason styling via class doesn't work.
    // Maybe css-doodle itself is overwriting?
    const dStyle = {
      width: '100vw',
      height: '100vh',
    }
    const fillViewport = this.props.fillViewport
    const directStyle = fillViewport ? dStyle : {}

    let doodle
    if (this.state.loaded) {
      doodle = <css-doodle style={directStyle}>{this.props.rule}</css-doodle>
    } else {
      doodle = <p>-</p>
    }

    return (
      <>
        {doodle}
      </>
    )
  }
}

export default Doodle
