import React from 'react'
import './style.scss'
import FlyingShapes from '../FlyingShapes'
import NewDoodle from '../NewDoodle'

class DoodleBackground extends React.Component {
  render() {
    return (
      <div className="doodleBackground">
        <FlyingShapes
          fillViewport
        />
      </div>
    )
  }
}

export default DoodleBackground
