import React from 'react'
import Sidebar from '../Sidebar'
import Links from '../Links'
import './style.scss'

class BaseGrid extends React.Component {
  render() {
    const { children } = this.props

    const {
      copyright,
      author,
    } = this.props.data.site.siteMetadata

    return (
      <div className="baseGrid">
        <div className="sidebarX">
          <Sidebar {...this.props} />
        </div>
        <div className="contentX">
          {children}
        </div>
        <div className="footerX">
          <Links data={author} />
          <div className="copyright">{copyright}</div>
        </div>
      </div>
    )
  }
}

export default BaseGrid
