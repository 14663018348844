import React from 'react'
import Doodle from '../Doodle/Doodle'

class FlyingShapes extends React.Component {
  render() {
    return (
      <Doodle
        {...this.props}
        rule={`
          :doodle {
            @grid: 12x12;
            @size: 20em;
            grid-gap: @rand(3,35)px;
          }

          transition: all .4s ease;
          // border-top: 1px solid hsl(@rand(137.50deg, 360deg), 80%, 80%);
          // border-right: 1px solid hsl(@rand(137.50deg, 360deg), 80%, 80%);

          @random(.4) {
            :after {
              content: \\@hex(@rand(9632, 9687));
              color: hsl(@rand(137.50deg, 360deg), 80%, 80%);
            }
          }

          @keyframes turn {
             0% {
              transform: rotate(0deg);
             }
             100% {
              transform: rotate(360deg);
             }
          }

          @keyframes bounce {
             0% {
              transform: scale(10);
             }
             // 50% {
             //  transform: scale(1);
             // }
             100% {
              transform: scale(.1);
             }
          }

          animation: turn @rand(3,60)s ease-out infinite;

          @random(.4) {
            animation: bounce @rand(3,10)s ease-out infinite;
          }
        `}
      />
    )
  }
}

export default FlyingShapes
